<template>
    <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M31.7678 11.7678C32.7441 10.7915 32.7441 9.20854 31.7678 8.23223C30.7915 7.25592 29.2085 7.25592 28.2322 8.23223L20 16.4645L11.7678 8.23223C10.7915 7.25592 9.20854 7.25592 8.23223 8.23223C7.25592 9.20854 7.25592 10.7915 8.23223 11.7678L16.4645 20L8.23223 28.2322C7.25592 29.2085 7.25592 30.7915 8.23223 31.7678C9.20854 32.7441 10.7915 32.7441 11.7678 31.7678L20 23.5355L28.2322 31.7678C29.2085 32.7441 30.7915 32.7441 31.7678 31.7678C32.7441 30.7915 32.7441 29.2085 31.7678 28.2322L23.5355 20L31.7678 11.7678Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'CloseIcon',
    };
</script>
