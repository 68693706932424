<template>
    <svg viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.49961 16.65C13.7247 16.65 17.1496 13.2249 17.1496 8.99998C17.1496 4.77505 13.7248 1.34998 9.49961 1.34998C5.27454 1.34998 1.84961 4.7749 1.84961 8.99998C1.84961 13.225 5.27439 16.65 9.49961 16.65ZM9.92463 4.02753C10.629 4.02753 11.1997 4.59836 11.1997 5.30259C11.1997 6.00682 10.629 6.57765 9.92463 6.57765C9.22026 6.57765 8.64957 6.00682 8.64957 5.30259C8.64971 4.5984 9.22026 4.02753 9.92463 4.02753ZM9.49961 7.17243C10.7747 7.17243 11.0839 7.92472 10.9411 8.85273L10.3496 12.6976C10.3496 12.6976 10.7746 12.6976 11.6247 12.2726C11.6247 12.2726 11.1997 13.9727 9.49961 13.9727C8.22455 13.9727 7.91537 13.2204 8.05813 12.2924L8.64971 8.44745C8.64971 8.44745 8.22469 8.44745 7.37465 8.87247C7.37465 8.87247 7.79967 7.17243 9.49957 7.17243H9.49961Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'InfoCircleIcon',
    };
</script>
