<template>
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.9998 1.80005C6.3796 1.80005 1.7998 6.37985 1.7998 12C1.7998 17.6203 6.3796 22.2001 11.9998 22.2001C17.62 22.2001 22.1998 17.6203 22.1998 12C22.1998 6.37985 17.62 1.80005 11.9998 1.80005ZM16.0798 12.765H12.7648V16.0801C12.7648 16.4983 12.418 16.8451 11.9998 16.8451C11.5816 16.8451 11.2348 16.4983 11.2348 16.0801V12.765H7.91981C7.50161 12.765 7.15481 12.4182 7.15481 12C7.15481 11.5818 7.50161 11.235 7.91981 11.235H11.2348V7.92005C11.2348 7.50185 11.5816 7.15505 11.9998 7.15505C12.418 7.15505 12.7648 7.50185 12.7648 7.92005V11.235H16.0798C16.498 11.235 16.8448 11.5818 16.8448 12C16.8448 12.4182 16.498 12.765 16.0798 12.765Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'PlusCircleIcon',
    };
</script>
