<template>
    <div class="pagination">
        <p :class="{ pagination__item: true, pagination__item_disabled: 1 === current }" @click="changePage(current - 1)">
            <img src="~@/assets/images/arrow-left.png" alt="previous" />
        </p>

        <p
            v-for="page in pages"
            :class="{ pagination__item: true, pagination__item_active: page === current, pagination__item_disabled: '...' === page }"
            @click="changePage(page)"
        >
            {{ page }}
        </p>

        <p :class="{ pagination__item: true, pagination__item_disabled: total === current }" @click="changePage(current + 1)">
            <img src="~@/assets/images/arrow-right.png" alt="next" />
        </p>
    </div>
</template>

<script>
    const PAGE_LIMIT = 7;
    const PAGE_RANGE = 5;
    const PAGE_PLACEHOLDER = '...';

    export default {
        name: 'Pagination',
        props: {
            current: {
                type: Number,
                default: 1,
            },
            total: {
                type: Number,
                required: true,
            },
        },
        computed: {
            pages() {
                if (this.total <= PAGE_LIMIT) {
                    return Array.from(Array(this.total), (_, index) => index + 1);
                }

                if (this.current <= PAGE_RANGE) {
                    return [...Array.from(Array(PAGE_RANGE), (_, index) => index + 1), PAGE_PLACEHOLDER, this.total];
                }

                if (this.current > this.total - PAGE_RANGE) {
                    return [1, PAGE_PLACEHOLDER, ...Array.from(Array(PAGE_RANGE), (_, index) => index + this.total - PAGE_RANGE + 1)];
                }

                return [1, PAGE_PLACEHOLDER, this.current - 1, this.current, this.current + 1, PAGE_PLACEHOLDER, this.total];
            },
        },
        methods: {
            changePage(page) {
                if (isNaN(page) || page < 1 || page > this.total) return;

                this.$emit('change', page);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .pagination {
        position: relative;
        display: flex;
        align-items: center;
    }

    .pagination__item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4.6rem;
        height: 4.6rem;
        border-radius: 0.8rem;
        background-color: #191f2e;
        color: #7c869d;
        cursor: pointer;
        font-size: 1.6rem;
        font-weight: 500;
        transition: background-color 0.3s, opacity 0.3s;

        &:not(:first-child) {
            margin-left: 0.6rem;
        }

        &:first-child {
            margin-right: 2rem;
        }

        &:last-child {
            margin-left: 2.6rem;
        }

        &:hover {
            opacity: 0.75;
        }

        &_active,
        &_disabled {
            pointer-events: none;
        }

        &_active {
            background-color: #3760cb;
            color: #ffffff;
        }

        img {
            width: 2.2rem;
            height: 2.2rem;
        }
    }
</style>
